import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import LabelMakerResult from '../components/label-maker/label-maker-result';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { localizedUrl } from '../utils/js/locale-utils';

const CreateResultPage = ({ intl, pageContext }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: `label-maker.title.${pageContext.category}` })} />
    <Helmet>
      <link rel="canonical" href={localizedUrl(`${pageContext.slug}/shape`)} />
      <link rel="stylesheet" type="text/css" href="/print.css" media="print" />
    </Helmet>
    <div className="">
      <LabelMakerResult
        category={pageContext.category}
        categorySlug={pageContext.slug}
      />
    </div>
  </Layout>
);

CreateResultPage.propTypes = {
  intl: PropTypes.object,
  pageContext: PropTypes.object,
};

export default injectIntl(CreateResultPage);
