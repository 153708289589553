export const getLabel = (store) => store.label;

export const getLabelShape = (store) => (getLabel(store) ? getLabel(store).shape : '');

export const getLabelImage = (store) => (getLabel(store) ? getLabel(store).image : '');

export const getLabelBorder = (store) => (getLabel(store) ? getLabel(store).border : '');

export const getLabelText = (store) => (getLabel(store) ? getLabel(store).text : {});

export const getLabelPrint = (store) => (getLabel(store) ? getLabel(store).print : {});
