const LABEL_MAKER_LOCALSTORAGE_NAME = 'label-maker';

export const getLabelFromLocalstorage = (category) => {
  if (typeof window === 'undefined') return null;

  const LOCALSTORAGE_NAME = category ? `${LABEL_MAKER_LOCALSTORAGE_NAME}-${category}` : LABEL_MAKER_LOCALSTORAGE_NAME;

  try {
    const labelState = JSON.parse(localStorage.getItem(LOCALSTORAGE_NAME));
    const limitDate = new Date();
    limitDate.setHours(limitDate.getHours() - 12);

    if (
      !labelState.label
      || !labelState.label.text
      || !labelState.date
      || new Date(labelState.date).getTime() < limitDate.getTime()
    ) {
      localStorage.removeItem(LOCALSTORAGE_NAME);
      return null;
    }

    return labelState.label;
  } catch (err) {
    localStorage.removeItem(LOCALSTORAGE_NAME);
    return null;
  }
};

export const saveLabelToLocalstorage = (label, category) => {
  if (typeof window === 'undefined') return;

  const LOCALSTORAGE_NAME = category ? `${LABEL_MAKER_LOCALSTORAGE_NAME}-${category}` : LABEL_MAKER_LOCALSTORAGE_NAME;

  localStorage.setItem(LOCALSTORAGE_NAME, JSON.stringify({
    ...label,
    date: new Date(),
  }));
};

export const deleteLabelFromLocalstorage = (category) => {
  if (typeof window === 'undefined') return;

  const LOCALSTORAGE_NAME = category ? `${LABEL_MAKER_LOCALSTORAGE_NAME}-${category}` : LABEL_MAKER_LOCALSTORAGE_NAME;

  localStorage.removeItem(LOCALSTORAGE_NAME);
};
