import axios from 'axios';
import { Link } from 'gatsby';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import JSONData from '../../conf/label-maker.json';
import { setLabel as setLabelAction } from '../../state/actions';
import { getLabel } from '../../state/selectors';
import { isFacebookApp, isInstagramApp } from '../../utils/js/detect-browser';
import { getFirebaseUser } from '../../utils/js/firebase';
import { getValueFromObject } from '../../utils/js/get-value-from-object';
import { localizedUrl } from '../../utils/js/locale-utils';
import { getLabelFromLocalstorage } from '../../utils/js/localstorage';
import labelMakerResultStyles from './label-maker-result.module.scss';

const LabelMakerResult = ({
  label, setLabel, category, categorySlug,
}) => {
  const [labelUrl, setlabelUrl] = useState(null);
  const [labelHash, setlabelHash] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [hasEmptyError, setEmptyError] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [hasFileError, setFileError] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const LABEL_MAKER_URL = '/api/labelMaker';
  const PDF_LABEL_MAKER_URL = '/api/pdfLabelMaker';
  const isMounted = useRef(true);

  useEffect(() => {
    let validLabel;

    if (label) {
      if (label.default) {
        const localLabel = getLabelFromLocalstorage(category);
        validLabel = localLabel ? { ...localLabel, default: false } : { ...label, default: false };
        setLabel(validLabel);
      } else {
        validLabel = label;
      }

      (async function getLabelFromServer() {
        const user = await getFirebaseUser();
        const idToken = user ? await user.getIdToken() : null;
        setTokenId(idToken);

        axios.get(LABEL_MAKER_URL, {
          params: {
            shape: validLabel.shape,
            color: validLabel.border,
            illustration: validLabel.image,
            line1: validLabel.text.line1,
            line2: validLabel.text.line2,
            date: validLabel.text.date,
            textFont: validLabel.text.font,
            textColor: validLabel.text.color,
            textSize: validLabel.text.size,
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }).then((resultData) => {
          if (isMounted.current) {
            if (resultData.data) {
              setlabelUrl(resultData.data.url);
              setlabelHash(resultData.data.hash);
            }
            setLoading(false);
          }
        }).catch(() => {
          if (isMounted.current) {
            setError(true);
            setLoading(false);
          }
        });
      }());
    } else {
      // TODO: Correct redirect to first step
      setEmptyError(true);
      setError(true);
      setLoading(false);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  const labelWidth = label.print.width;
  const labelHeight = labelWidth / (getValueFromObject(JSONData, `labels.${label.shape}.ratio`) || 1);
  const labels = [];
  for (let i = 0; i < label.print.number; i += 1) {
    labels.push(
      <div
        className={labelMakerResultStyles.labelContainer}
        style={{ width: `${labelWidth}cm`, height: `${labelHeight}cm` }}
        key={`label-${i}`}
      >
        {loading ? (
          <i
            className={`icon-${label.shape}-outline noPrint ${labelMakerResultStyles.labelSkeleton} ${labelMakerResultStyles.loading}`}
            style={{ fontSize: `${labelWidth}cm`, lineHeight: `${labelHeight}cm` }}
            aria-hidden="true"
          />
        ) : ''}
        { !loading && labelUrl ? (
          <img src={labelUrl} className={`printable ${labelMakerResultStyles.label}`} alt="Mon étiquette" />
        ) : ''}
      </div>,
    );
  }

  let messageBlock;
  if (loading) messageBlock = <FormattedMessage id="label-maker.result.congratulationLoading" />;
  else if (hasEmptyError) messageBlock = <FormattedMessage id="label-maker.result.error-empty" />;
  else if (hasError) messageBlock = <FormattedMessage id="label-maker.result.error" />;
  else {
    messageBlock = (
      <>
        <p><FormattedMessage id="label-maker.result.congratulation" /></p>
        <p><FormattedMessage id="label-maker.result.congratulation2" /></p>
      </>
    );
  }

  const downloadImage = () => {
    if (isFacebookApp() || isInstagramApp()) {
      window.open(labelUrl, '_blank');
    } else {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = `${label.text.line1 || label.text.line2 || label.text.date}.png`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      };
      xhr.open('GET', labelUrl);
      xhr.send();
    }
  };

  const downloadPdf = () => {
    setLoadingFile(true);
    setFileError(false);
    axios({
      url: `${PDF_LABEL_MAKER_URL}?labelHash=${labelHash}&labelWidth=${label.print.width}&labelNumber=${label.print.number}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    }).then((response) => {
      if (isFacebookApp() || isInstagramApp()) {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${label.text.line1 || label.text.line2 || label.text.date}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
      setLoadingFile(false);
    }).catch(() => {
      setFileError(true);
      setLoadingFile(false);
    });
  };

  return (
    <div>
      <div className="onlyPrint">
        {labels}
      </div>
      <section className="m-b-lg m-t-lg noPrint">
        <div className="container">
          <div className="m-b-md">
            <Link
              to={localizedUrl(`${categorySlug}/print`)}
              className="button is-info is-small"
            >
              <span className="icon is-small">
                <i className="fas fa-chevron-left" />
              </span>
              <span>
                <FormattedMessage id="label-maker.result.return" />
              </span>
            </Link>
          </div>
          <div className={`notification has-icon ${hasError ? 'is-danger' : ''} ${!hasError && !loading ? 'is-success' : ''} ${loading ? 'is-info' : ''}`}>
            <span className="icon">
              <i className={`fas fa-lg ${hasError ? 'fa-exclamation-circle' : ''} ${!hasError && !loading ? 'fa-check-circle' : ''} ${loading ? 'loader' : ''}`} />
            </span>
            <span className="p-l-lg">
              {messageBlock}
            </span>
          </div>
        </div>
      </section>
      <section className="hero is-light noPrint">
        <div className="container p-b-lg">
          <div className="has-text-centered m-t-lg m-b-lg">
            <button
              type="button"
              className={`button is-medium is-info ${loading ? 'is-loading' : ''}`}
              onClick={() => window.print()}
              disabled={loading || hasError}
            >
              <span className="icon is-small">
                <i className="fas fa-print" />
              </span>
              <span>
                <FormattedMessage id="label-maker.result.print" />
              </span>
            </button>
          </div>
          <div className={`${labelMakerResultStyles.page} noPrint card m-b-lg`}>
            {labels}
          </div>
          <div className="level m-b-lg">
            <div className="level-left">
              <div className="level-content has-text-centered p-l-md p-r-md">
                <button
                  type="button"
                  onClick={downloadImage}
                  className={`button is-info ${loading ? 'is-loading' : ''}`}
                  disabled={loading || hasError}
                >
                  <span className="icon is-small">
                    <i className="fas fa-image" />
                  </span>
                  <span>
                    <FormattedMessage id="label-maker.result.download-image" />
                  </span>
                </button>
                <p className="text-smaller p-t-xs has-text-grey">
                  <FormattedMessage id="label-maker.result.download-image-helper" />
                </p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-content has-text-centered p-r-md p-l-md">
                <button
                  type="button"
                  onClick={downloadPdf}
                  className={`button is-info ${loading || loadingFile ? 'is-loading' : ''}`}
                  disabled={loading || loadingFile || hasError}
                >
                  <span className="icon is-small">
                    <i className="fas fa-file" />
                  </span>
                  <span>
                    <FormattedMessage id="label-maker.result.download-file" />
                  </span>
                </button>
                <p className="text-smaller p-t-xs has-text-grey">
                  <FormattedMessage id="label-maker.result.download-file-helper" />
                </p>
                { hasFileError ? (
                  <p className="text-smaller p-t-xs has-text-danger">
                    <FormattedMessage id="label-maker.result.download-file-error" />
                  </p>
                ) : '' }
              </div>
            </div>
          </div>
          <p className="p-l-md p-r-md">
            <FormattedMessage id="label-maker.result.problem" />
            <Link to={localizedUrl('/help')}><FormattedMessage id="label-maker.result.help-page" /></Link>
            <FormattedMessage id="label-maker.result.problem2" />
            <Link to={localizedUrl('/contact')}><FormattedMessage id="label-maker.result.contact-page" /></Link>
            .
          </p>
          <p className="p-l-md p-r-md">
            <FormattedMessage id="label-maker.result.thanks" />
            <Link to={localizedUrl('/guestbook')}><FormattedMessage id="label-maker.result.guestbook" /></Link>
            .
          </p>
        </div>
      </section>
    </div>
  );
};

LabelMakerResult.propTypes = {
  label: PropTypes.object,
  setLabel: PropTypes.func,
  category: PropTypes.string,
  categorySlug: PropTypes.string,
};

const mapStateToProps = (state) => ({
  label: getLabel(state),
});

const mapDispatchToProps = {
  setLabel: setLabelAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LabelMakerResult));
