import {
  SET_SHAPE, SET_IMAGE, SET_BORDER, SET_TEXT, SET_PRINT, SET_LABEL,
} from './action-types';

export const setLabel = (label) => ({
  type: SET_LABEL,
  payload: {
    label,
  },
});

export const setShape = (shape) => ({
  type: SET_SHAPE,
  payload: {
    shape,
  },
});

export const setImage = (image) => ({
  type: SET_IMAGE,
  payload: {
    image,
  },
});

export const setBorder = (border) => ({
  type: SET_BORDER,
  payload: {
    border,
  },
});

export const setText = (text) => ({
  type: SET_TEXT,
  payload: {
    text,
  },
});

export const setPrint = (print) => ({
  type: SET_PRINT,
  payload: {
    print,
  },
});
